@charset "utf-8";
@import "foundation";

@include foundation-everything;

// Our variables

$heading-font: "Nunito Sans", "Sans Serif";

$title-font-family: $heading-font;
$title-font-weight: 700;
$title-font-size: 1.25rem;
$subtitle-font-size: 0.75rem;
$navigation-font-size: 1rem;

$header-font-family: $heading-font;
$header-font-weight: 700;
$header-background-color: #0a2233;

$brand-color: $white;
$footer-color: #0a2233;
$footer-border-color: #d9d9d9;
$navigation-color: #ebf0f1;

$spacing-unit: 30px;

$footer-font-size: 1rem;

body {
    background-color: $white;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: $header-font-family;
    text-transform: uppercase;
}

header.site-header {
    background-color: $header-background-color;

    div.cell,
    nav.cell {
        padding-top: 0.75rem;
        padding-bottom: 1.5rem;
    }

    .logo {
        float: left;
    }

    .branding {
        margin-top: 2.25rem;
        margin-left: 1rem;
        float: left;

        h1 {
            font-family: $title-font-family;
            font-weight: $title-font-weight;
            font-size: $title-font-size;
            color: $brand-color;
            margin: 0;
        }

        h2 {
            font-family: $title-font-family;
            font-weight: $title-font-weight;
            font-size: $subtitle-font-size;
            color: $brand-color;
            margin: 0;
        }
    }
}

header.site-header nav,
header.subpage-header nav {
    padding: 0.25rem 0;

    ul {
        li {
            a,
            a:link {
                color: $navigation-color;
                text-transform: uppercase;
                font-family: $title-font-family;
                font-weight: $title-font-weight;
                font-size: $navigation-font-size;
                transition: all 0.2s ease;
            }
        }
    }
}

header.subpage-header {
    background-color: $header-background-color;

    div.cell,
    nav.cell {
        padding-top: 0.5rem;
        padding-bottom: 0.75rem;
    }

    .logo {
        float: left;
    }

    .branding {
        margin-top: 0.8rem;
        margin-left: 1rem;
        float: left;

        h1 {
            font-family: $title-font-family;
            font-weight: $title-font-weight;
            font-size: $title-font-size;
            color: $brand-color;
            margin: 0;
        }
    }

    nav {
        margin-top: 0.5rem;
    }
}

#content {
    .home,
    .page,
    .post {
        padding-top: 2rem;
    }

    .detail {
        padding-bottom: 2rem;

        ul {
            //list-style: none;
            li {
                margin-left: 0;
                margin-top: 1rem;
            }
            margin-bottom: 1rem;
        }

        h3 {
            text-align: left;
            margin: 2rem 0 1rem;
        }

        .appstore {
            text-align: center;
            padding: 4em 0 2em;
            p {
                font-weight: 700;
            }
        }
    }

    .post article {
        margin-bottom: 4rem;

        .img-left {
            clear: left;
            float: left;
            width: 120px;
            margin-right: 2em;
        }
    }

    article.extract {
        h3 {
            a,
            a:link,
            a:visited {
                color: $black;

                &:hover {
                    text-decoration: underline;
                }
            }
            margin-bottom: 0;
        }

        p:nth-last-child(2) {
            margin-bottom: 0;
        }

        margin-bottom: 2rem;
    }

    article.app {
        margin-bottom: 4rem;

        h5 {
            margin-top: 1rem;
            a,
            a:link,
            a:visited {
                color: $black;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .post-time {
        margin-bottom: 0.75rem;

        time {
            font-style: italic;
            font-weight: 400;
            font-size: 0.8rem;
            color: $dark-gray;
        }
    }

    .readmore {
        a,
        a:link,
        a:visited {
            font-style: italic;
            font-size: 0.8rem;
        }

        span.glyphicon {
            color: $light-gray;
        }
    }
}

.post-extract {
    h2 {
        margin: 1.5em 0 0;
    }

    &.hero {
        position: relative;
        top: -60px;
        background-color: #fff;
        z-index: 100;
    }
}

.content-ads {
    text-align: center;
}

img.centered {
    display: block;
    margin: auto;
}

.gallery figure {
    margin: 3rem 0;
}

$hero-height: 40vh;
$hero-page-height: 60vh;

.hero-section {
    background-size: cover;
    height: $hero-height;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.6rem;

    .hero-section-text {
        color: $white;
        text-shadow: 1px 1px 2px $black;
        filter: none;
    }
}

.hero {
    position: relative;
    width: 100%;
    height: $hero-page-height;
    overflow: hidden;

    .hero-page {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: cover;
        background-position: bottom left;
        filter: blur(5px);
        filter: brightness(60%);
    }

    .hero-page-text {
        position: relative;
        top: 10vh;
        color: $white;
        text-shadow: 1px 1px 2px $black;

        h3.h1 {
            margin-bottom: 0;
            ///line-height: 1.0;
        }

        .hero-appstore {
            margin-top: 3vh;
        }
    }
}

.screenshot {
    .screenshot-caption {
        text-align: center;
        font-style: italic;
        font-size: 0.75rem;
        margin-top: 0.25rem;
        color: $dark-gray;
    }
}

footer {
    background-color: $footer-color;
    border-top: 1px solid $footer-border-color;
    color: $white;
    font-family: $heading-font;
    font-size: $footer-font-size;
    padding-top: 24px;
    padding-bottom: 24px;
    letter-spacing: 2px;
    text-transform: uppercase;
}
